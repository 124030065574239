import { useRouter } from "next/router";
import React from "react";
import LandingButton from "../LandingButton/LandingButton";
import {
  LandingFeaturesInfo,
  LandingFeaturesList,
  LandingFeaturesStyled,
  LandingFeaturesTitle,
  PoweredByLogo,
  PoweredByText,
  DataInfo,
} from "./LandingFeatures.style";
import { pushEvent } from "@tm/utility/analytics";
import { FootNote } from "../LandingCurrDel/LandingCurrDel.style";
import { Image } from "../Image";
import ReactMarkdown from "react-markdown";

const LandingFeatures = ({ data, featureImgs }: any) => {
  const router = useRouter();
  const clickHandler = () => {
    if (data?.buyNowEvent) {
      pushEvent(data.buyNowEvent);
    }
    if (data?.buttonRedirectionUrl) router.push(data.buttonRedirectionUrl);
  };
  return (
    <LandingFeaturesStyled id="feat_ref">
      <LandingFeaturesTitle>{data.title}</LandingFeaturesTitle>
      <LandingFeaturesInfo dangerouslySetInnerHTML={{ __html: data.info }} />
      <LandingFeaturesList>
        {data.featList &&
          data.featList.map((item: any, index: number) => {
            return <li key={index}>{item}</li>;
          })}
      </LandingFeaturesList>
      {data.buttonTxt ? (
        <LandingButton
          margin="auto 0 30px"
          customTitle={data.buttonTxt}
          handleClick={clickHandler}
          padding="12px 40px"
        />
      ) : data.buttonFallbackText ? (
        <ReactMarkdown>{data.buttonFallbackText}</ReactMarkdown>
      ) : (
        <>
          <br />
          Coming soon
          <br />
          <br />
        </>
      )}
      {data.dataInfo && <DataInfo>{data.dataInfo}</DataInfo>}
      <FootNote>
        <PoweredByText>{data?.poweredBy?.title}</PoweredByText>
        {data?.poweredBy?.logos?.map((item: any, index: number) => {
          return (
            <PoweredByLogo key={index}>
              <Image {...item} key={index} />
            </PoweredByLogo>
          );
        })}
      </FootNote>
      {data.aniImg == "img1" && (
        <React.Fragment>
          <Image
            id="lug1_ref"
            width="41"
            height="148"
            className="lug1"
            {...data?.cardImgs?.[0]}
            key={0}
          />
          <Image
            id="lug2_ref"
            width="70"
            height="134"
            alt=""
            className="lug2"
            {...data?.cardImgs?.[1]}
            key={1}
          />
          <Image
            id="shield_ref"
            width="85"
            height="85"
            className="shield"
            {...data?.cardImgs?.[2]}
            key={2}
          />
          <Image
            id="shieldRect_ref"
            width="141"
            height="28"
            className="shieldRect"
            {...data?.cardImgs?.[3]}
            key={3}
          />
        </React.Fragment>
      )}
      {data.aniImg == "img2" && (
        <React.Fragment>
          <Image
            id="app1_ref"
            width="120"
            height="30"
            className="app1"
            {...data?.cardImgs?.[0]}
            key={0}
          />
          <Image
            id="app2_ref"
            width="130"
            height="30"
            className="app2"
            {...data?.cardImgs?.[1]}
            key={1}
          />
          <Image
            id="coins_ref"
            width="67"
            height="70"
            className="coins"
            {...data?.cardImgs?.[2]}
            key={2}
          />
          <Image
            id="moneyBag_ref"
            width="85"
            height="116"
            className="moneyBag"
            {...data?.cardImgs?.[3]}
            key={3}
          />
        </React.Fragment>
      )}
      {data.aniImg == "img1" && (
        <Image
          width="176"
          height="135"
          className="featureVector2"
          {...featureImgs?.[0]}
        />
      )}
      {data.aniImg == "img2" && (
        <Image
          width="208"
          height="135"
          className="featureVector"
          {...featureImgs?.[1]}
        />
      )}
    </LandingFeaturesStyled>
  );
};

export default LandingFeatures;
