import styled from "styled-components";

export const LandingFeaturesStyled = styled.section`
  background: #ffffff;
  border-radius: 16px;
  padding: 36px 40px 70px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 500px;

  & .greenText {
    color: #0b8041;
    font-weight: 700;
  }
  .lug1, .lug2, .shield, .shieldRect, .coins, .moneyBag, .app1, .app2, .featureVector1, .featureVector2 {
    position: absolute;
  }
  & .lug2 {
    width: 70px;
    height: 134px;
    right: 191px;
    bottom: 104px;
    z-index: 2;
  }
  & .lug1 {
    width: 41px;
    height: 148px;
    right: 17px;
    bottom: 104px;
    z-index: 2;
  }
  & .shield {
    width: 85px;
    height: 85px;
    right: 80px;
    bottom: 103px;
    z-index: 5;
    opacity: 0;
    transform: scale(0.5);
  }
  & .shieldRect {
    width: 143px;
    height: 28px;
    position: absolute;
    right: 50px;
    bottom: 96px;
    z-index: 4;
    opacity: 0;
  }
  & .coins {
    width: 67px;
    height: 70px;
    right: 40px;
    bottom: 97px;
    z-index: 3;
    opacity: 0;
  }
  & .moneyBag {
    width: 85px;
    height: 116px;
    right: 11px;
    bottom: 121px;
    z-index: 2;
  }
  & .app1 {
    width: 120px;
    height: 30px;
    right: 83px;
    bottom: 194px;
    z-index: 4;
    opacity: 0;
  }
  & .app2 {
    width: 130px;
    height: 30px;
    right: 105px;
    bottom: 109px;
    z-index: 4;
    opacity: 0;
  }
  & .featureVector {
    width: 176px;
    height: 135px;
    position: absolute;
    bottom: 70px;
    right: 0;
    z-index: 1;
  }
  & .featureVector2 {
    width: 208px;
    height: 135px;
    position: absolute;
    bottom: 70px;
    right: 0;
    z-index: 1;
  }
  }
`;

export const LandingFeaturesTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #000000;
  margin-bottom: 24px;
`;

export const LandingFeaturesInfo = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-size: 22px;
  line-height: 30px;
  color: #4a4a4a;
  margin-bottom: 24px;
`;

export const LandingFeaturesList = styled.ul`
  font-size: 18px;
  line-height: 32px;
  padding-left: 18px;
  gap: 10px;

  & li {
    position: relative;
    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 8px;
    background: #000000;
    border-radius: 14px;
  }
`;

export const PoweredByText = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-right: 20px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const DataInfo = styled.span`
  width: 326px;
  font-family: "Open Sans";
  color: #757575;
  margin: auto 0 16px;
  display: inline-block;
`;

export const PoweredByLogo = styled.div`
  margin: 0 16px 0 0;
`;
