import { useInsuranaceAnimation } from "@tm/ui/hooks";
import {
  LandingGreySec,
  LandingGreySecWrap,
} from "../../shared-styled-components";
import LandingFeatures from "../LandingFeatures/LandingFeatures";
import { Flex } from "./index.style";

const InsuranceSection = (props: any) => {
  useInsuranaceAnimation();
  return (
    <LandingGreySec padding="0 0 80px 0">
      <LandingGreySecWrap>
        <Flex>
          <LandingFeatures
            featRef={props?.featRef}
            lug1={props?.lug1}
            lug2={props?.lug2}
            shield={props?.shield}
            shieldRect={props?.shieldRect}
            data={props?.insurance}
            featureImgs={props?.featureImgs}
          />
          <LandingFeatures
            featRef={props?.featRef}
            app1={props?.app1}
            app2={props?.app2}
            moneyBag={props?.moneyBag}
            coins={props?.coins}
            data={props?.travelLoan}
            featureImgs={props?.featureImgs}
          />
        </Flex>
      </LandingGreySecWrap>
    </LandingGreySec>
  );
};

export default InsuranceSection;
